import { Font, Switch, Text } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import React, { Dispatch, SetStateAction } from 'react'
import { css } from 'react-emotion'

import { ISettings } from '../../../models/ISettings'

interface RefreshTokenRotationProps {
  isLoading: boolean
  isPkceFlow: boolean
  shouldRotateRefreshToken: boolean | undefined
  setSettings: Dispatch<SetStateAction<ISettings | undefined>>
}

export const RefreshTokenRotation: React.FC<RefreshTokenRotationProps> = ({
  isLoading,
  isPkceFlow,
  shouldRotateRefreshToken,
  setSettings,
}) => {
  const [refreshTokenRotationSwitchLabel] = useLocalization(
    'rotate.refresh.token.everytime.it.is.used',
    'Rotate refresh token everytime it is used'
  )

  const setSettingsCallback = (settings) => ({
    ...settings,
    shouldRotateRefreshToken: !settings?.shouldRotateRefreshToken,
  })

  return (
    <div>
      <Font type={'h4'}>
        <Localization
          messageKey={'rotate.refresh.token'}
          fallback={'Rotation'}
        />
      </Font>
      <div className={infoStyles}>
        <Text.Small>
          <Localization
            messageKey={'enable.this.option.to.issue.a.new.refresh.token'}
            fallback={
              'Enable this option to issue a new refresh token whenever the refresh token is used. ' +
              'Your application must store the new refresh token after every refresh.'
            }
          />
        </Text.Small>
      </div>
      <div className={switchStyles}>
        <Switch
          label={refreshTokenRotationSwitchLabel}
          name={'RotateRefreshToken'}
          onChange={() => setSettings(setSettingsCallback)}
          checked={isPkceFlow || shouldRotateRefreshToken || false}
          labelFirst={true}
          disabled={isPkceFlow || isLoading}
        />
      </div>
    </div>
  )
}
RefreshTokenRotation.displayName = 'RefreshTokenRotation'

const switchStyles = css`
  margin-top: 18px;
`

const infoStyles = css`
  margin: 6px 0;
  width: 566px;
  margin-bottom: 18px;
`
