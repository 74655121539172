import { Font, primary, Text } from '@phoenix/all'
import ImageUpload from '@workfront/image-upload'
import { Localization, useLocalization } from '@workfront/localize-react'
import React, { useEffect, useState } from 'react'
import { css } from 'react-emotion'

import {
  ImageObject,
  ImageUploadContainerProps,
  IUseImageUpload,
  Nullable,
} from '../../../../models/ILogo'
import { solidLine } from '../../../../utils/commonClasses'
import {
  cropSizes,
  otherFormats,
  validOtherFormats,
} from '../../../../utils/constants'
import { imgObjFromId } from '../../../../utils/utils'

export const Logo: React.FC<ImageUploadContainerProps> = ({
  applicationId,
  logoPath,
  onLogoUpload,
  onLogoRemove,
}) => {
  const [uploadedImage, loading, , onImgUpload] = useImageUpload(applicationId)
  const [imageData, setImageData] = useState<Nullable<ImageObject>>(
    logoPath ? imgObjFromId(applicationId, logoPath) : null
  )

  useEffect(() => {
    if (!uploadedImage) return
    setImageData(uploadedImage)
  }, [uploadedImage])

  const onRemove = () => {
    setImageData(null)
    onLogoRemove()
  }

  const handleImageChange = (blob) => {
    onImgUpload(blob)
    onLogoUpload(blob)
  }

  const [imageUploadModalTitle] = useLocalization(
    'upload.application.logo',
    'Upload application logo'
  )

  const [imageUploadModalDescription] = useLocalization(
    'drag.and.zoom.to.crop',
    'Drag and zoom to crop the image for your application logo'
  )

  return (
    <div data-testid="logo-upload-section">
      <Font type="h4" style={{ marginBottom: '6px' }}>
        <Localization messageKey={'text.applogo.title'} fallback={'Logo'} />
      </Font>
      <div>
        <div className={itemStyle}>
          <Text className={grayColor}>
            <Localization
              messageKey={'logo.help.text'}
              fallback={'This displays a logo of your app.'}
            />
          </Text>
          <br />
          <Text className={grayColor}>
            <Localization
              messageKey={'logo.help.file.size'}
              fallback={'Maximum file size: 2 MB'}
            />
          </Text>
          <Text className={grayColor}>
            <Localization
              messageKey={'logo.help.image.size'}
              fallback={'Recommended size: 120 x 120px'}
            />
          </Text>
          <Text className={grayColor}>
            <Localization
              messageKey={'logo.help.image.format'}
              fallback={'Supported file formats: GIF, JPG, JPEG, PNG, SVG'}
            />
          </Text>
        </div>
        <ImageUpload
          onImgUpload={handleImageChange}
          imageEditor={true}
          loading={loading}
          imageFromProps={imageData}
          cropHeight={cropSizes.height}
          cropWidth={cropSizes.width}
          accept={otherFormats}
          multiple={false}
          onRemove={onRemove}
          title={'Title'}
          modalTitle={imageUploadModalTitle}
          modalDesc={imageUploadModalDescription}
          formatValidText={validOtherFormats}
        />
        <hr className={solidLine} />
      </div>
    </div>
  )
}

const itemStyle = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`
const grayColor = css`
  color: ${primary.gray(400)};
`

export const useImageUpload = (applicationId: string): IUseImageUpload => {
  const [data, setData] = useState<Nullable<ImageObject>>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [blob, setBlob] = useState<Nullable<Blob[]>>(null)
  useEffect(() => {
    if (!blob) return
    let isUnmounted = false
    setIsLoading(true)
    const uploadImage = async (b) => {
      const imgUrl = URL.createObjectURL(b)
      if (isUnmounted) return
      setData(imgObjFromId(applicationId, imgUrl))
      setIsLoading(false)
    }
    blob.forEach((b) => uploadImage(b))
    return () => {
      isUnmounted = true
    }
  }, [blob, applicationId])
  return [data, isLoading, blob, setBlob]
}
