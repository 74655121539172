import { Phoenix } from '@phoenix/all'
import * as React from 'react'
import { Routes, Route } from 'react-router-dom'

import { Application } from './components/Application/Application'
import { Applications } from './components/Applications/Applications'

export const App: React.FC = () => {
  return (
    <div>
      <Phoenix injectContainers={false}>
        <Routes>
          <Route path="/setup/apps" element={<Applications />} />
          <Route path="/setup/apps/:applicationId" element={<Application />} />
        </Routes>
      </Phoenix>
    </div>
  )
}
