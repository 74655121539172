// @ts-ignore
import { Card, Font } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { SettingsService } from '../../../api/api'
import { ISettings, ISettingsResponse } from '../../../models/ISettings'
import { cardWrapperStyles, titleStyles } from '../../../utils/commonClasses'
import { RefreshTokenExpiration } from './RefreshTokenExpiration'
import { RefreshTokenRotation } from './RefreshTokenRotation'

interface RefreshTokenSettingsProps {
  applicationId: string
  isPkceFlow: boolean
  settings: ISettings | undefined
  setSettings: Dispatch<SetStateAction<ISettings | undefined>>
}

export const RefreshTokenSettings: React.FC<RefreshTokenSettingsProps> = ({
  applicationId,
  settings,
  setSettings,
  isPkceFlow,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true)
        const response: ISettingsResponse = await SettingsService.get(
          applicationId
        )
        setSettings(response.settings)
      } catch (error: unknown) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchSettings()
  }, [applicationId, setSettings])

  return (
    <div className={cardWrapperStyles}>
      <Font type="h3" className={titleStyles}>
        <Localization
          messageKey={'text.refresh.token.settings'}
          fallback={'Refresh token settings'}
        />
      </Font>
      <Card>
        <RefreshTokenRotation
          shouldRotateRefreshToken={settings?.shouldRotateRefreshToken}
          setSettings={setSettings}
          isLoading={isLoading}
          isPkceFlow={isPkceFlow}
        />
        <RefreshTokenExpiration
          absoluteDefaultValue={settings?.refreshTokenAbsoluteLifetime}
          inactivityDefaultValue={settings?.refreshTokenInactivityLifetime}
          setSettings={setSettings}
          isLoading={isLoading}
        />
      </Card>
    </div>
  )
}
RefreshTokenSettings.displayName = 'RefreshTokenSettings'
