import { SetupLayoutContent } from '@setup/components'
import { Localization } from '@workfront/localize-react'
import React from 'react'
import { css } from 'react-emotion'

import OAuth2PageContainer from '../OAuth2PageContainer'
import { ApplicationsList } from './ApplicationsList/ApplicationsList'
import { CreateApplication } from './CreateApplication/CreateApplication'

export const Applications: React.FC = () => (
  <OAuth2PageContainer
    pageTitle={
      <Localization
        messageKey={'applications'}
        fallback={'OAuth2 Applications'}
      />
    }
  >
    <SetupLayoutContent>
      <CreateApplication />
      <div className={contentWrapper}>
        <ApplicationsList />
      </div>
    </SetupLayoutContent>
  </OAuth2PageContainer>
)

const contentWrapper = css`
  padding: 24px;
`
