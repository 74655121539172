import { Font, Text } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
import React from 'react'
import { css } from 'react-emotion'

import { grayColor400 } from '../../../../utils/commonClasses'

export const PkceNote: React.FC = () => {
  return (
    <div>
      <Font type="h4" style={{ marginBottom: '3px' }}>
        <Localization
          messageKey={'text.clientsecret'}
          fallback={'Client Secret'}
        />
      </Font>
      <div className={itemStyle}>
        <Text>
          <Localization
            messageKey={'pkce.secret.description'}
            fallback={
              'Use Proof Key for Code Exchange (PKCE) instead of a client secret:'
            }
          />
        </Text>
        <ul className={infoList}>
          <li>
            <Text>
              <Localization
                messageKey={'pkce.secret.help.point.1'}
                fallback={
                  'The client app generates one-time keys Code verifier and Code challenge.'
                }
              />
            </Text>
          </li>
          <li>
            <Text>
              <Localization
                messageKey={'pkce.secret.help.point.2'}
                fallback={
                  'Code verifier is a random URL-safe string with a minimum length of 43 characters'
                }
              />
            </Text>
          </li>
          <li>
            <Text>
              <Localization
                messageKey={'pkce.secret.help.point.3'}
                fallback={
                  'Code challenge is Base64 URL-encoded SHA-256 hash of the code verifier'
                }
              />
            </Text>
          </li>
          <li>
            <Text>
              <Localization
                messageKey={'pkce.secret.help.point.4'}
                fallback={
                  'Sends Code challenge with authorizing request and Code verifier with the token request'
                }
              />
            </Text>
          </li>
        </ul>
        <Text>
          <Localization
            messageKey={'pkce.secret.help.point.5'}
            fallback={
              'You need to add code in your client app to generate the code verifier and code challenge'
            }
          />
        </Text>
      </div>
    </div>
  )
}

const itemStyle = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

const infoList = css`
  ${grayColor400};
  list-style: initial;
  padding-left: 18px;
`
