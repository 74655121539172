export const otherFormats = [
  'image/x-png',
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/svg+xml',
]

export const validOtherFormats = 'GIF, JPG, JPEG, PNG, SVG'

export const cropSizes = {
  height: 120,
  width: 120,
}

export const positionMapping = {
  1: 'left',
  2: 'center',
  3: 'right',
}
