// @ts-ignore
import {
  Alert,
  Button,
  Cell,
  Dialog,
  Font,
  H1,
  Layout,
  primary,
  Row,
  Text,
  toast,
  Tooltip,
} from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import { formatDistanceStrict, formatISO9075 } from 'date-fns'
import CopyIcon from 'phoenix-icons/dist/CopyIcon'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon'
import PlusIcon from 'phoenix-icons/dist/PlusIcon.js'
import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'react-emotion'

import { ClientSecretService } from '../../../../api/api'
import {
  IClientSecret,
  IClientSecretResponse,
  IClientSecretsResponse,
} from '../../../../models/IClientSecret'
import {
  actionWrapper,
  buttonStyles,
  DialogFooter,
  grayColor500,
  rowWrapper,
} from '../../../../utils/commonClasses'
import { maskClientSecret } from '../../../../utils/utils'

export const ClientSecrets: React.FC<{ applicationId: string }> = ({
  applicationId,
}) => {
  const [alertMessage] = useLocalization(
    'alert.copyclientsecret',
    'Store your client secrets in a very secure place. Copy your newly generated client secret, as you won’t be able to see it again.'
  )
  const [clientSecretGenSuccessMessage] = useLocalization(
    'toast.clientsecret.successfully.generated',
    'Client secret generated successfully'
  )
  const [clientSecretDeleteSuccessMessage] = useLocalization(
    'toast.clientsecret.successfully.deleted',
    'The secret key has been deleted'
  )
  const [copySuccessMessage] = useLocalization(
    'toast.copied.successfully',
    'Copied successfully'
  )
  const [copyContent] = useLocalization(
    'tooltip.copytoclipboard',
    'Copy to clipboard'
  )
  const [notePlaceholder] = useLocalization(
    'placeholder.clientsecret.notes',
    'Click to write notes'
  )

  const [clientSecrets, setClientSecrets] = useState<IClientSecret[]>([])
  const [newClientSecret, setNewClientSecret] = useState<IClientSecret | null>()
  const [secretIdToDelete, setSecretIdToDelete] = useState(null)
  const [showDialog, setShowDialog] = useState(false)

  const getApplicationClientSecrets = useCallback(async () => {
    const response: IClientSecretsResponse = await ClientSecretService.get(
      applicationId
    )
    setClientSecrets(response.clientSecrets)
  }, [applicationId])

  useEffect(() => {
    getApplicationClientSecrets()
  }, [getApplicationClientSecrets])

  const handleGenerateSecret = async () => {
    try {
      const response: IClientSecretResponse = await ClientSecretService.add(
        applicationId
      )

      if (newClientSecret) {
        setClientSecrets([
          ...clientSecrets,
          {
            ...newClientSecret,
            displayName: maskClientSecret(newClientSecret.displayName),
          },
        ])
      }

      setNewClientSecret(response.clientSecret)
      toast.success(clientSecretGenSuccessMessage)
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  const openDeleteDialog = (id) => {
    setShowDialog(true)
    setSecretIdToDelete(id)
  }

  const handleDelete = async () => {
    setShowDialog(false)
    if (!secretIdToDelete) return

    try {
      const { deleted } = await ClientSecretService.remove(
        applicationId,
        secretIdToDelete
      )
      if (!deleted) return

      if (secretIdToDelete === newClientSecret?.clientSecretId) {
        setNewClientSecret(null)
      } else {
        setClientSecrets(
          clientSecrets.filter(
            (clientSecret) => clientSecret.clientSecretId !== secretIdToDelete
          )
        )
      }

      toast.success(clientSecretDeleteSuccessMessage)
    } catch (error: unknown) {
      toast.error((error as Error).message)
    } finally {
      setSecretIdToDelete(null)
    }
  }

  const handleCopy = async (value: string) => {
    await navigator.clipboard.writeText(value)
    toast.success(copySuccessMessage)
  }

  const handleNoteChange = (noteValue: string, clientSecretIndex: number) => {
    const clientSecretsNew = [...clientSecrets]
    clientSecretsNew[clientSecretIndex].note = noteValue
    setClientSecrets(clientSecretsNew)
  }

  const saveNoteChange = async (noteValue: string, clientSecretId: string) => {
    try {
      const { updated } = await ClientSecretService.update(
        applicationId,
        clientSecretId,
        {
          note: noteValue,
        }
      )

      if (!updated) return
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  return (
    <div data-testid="client-secret-section">
      <Font type="h4" style={{ marginBottom: '19.5px' }}>
        <Localization
          messageKey={'text.clientsecret'}
          fallback={'Client Secret'}
        />
      </Font>
      <div className={width700}>
        {clientSecrets && clientSecrets[0] && (
          <Layout fullBleed={true}>
            <Row>
              <Cell span={5} className={nameCell}>
                <Text className={grayColor500}>
                  <Localization messageKey={'text.name'} fallback={'Name'} />
                </Text>
              </Cell>
              <Cell span={3}>
                <Text className={grayColor500}>
                  <Localization
                    messageKey={'text.last-usage'}
                    fallback={'Last usage'}
                  />
                </Text>
              </Cell>
              <Cell span={3}>
                <Text className={grayColor500}>
                  <Localization
                    messageKey={'text.creation-date'}
                    fallback={'Creation date'}
                  />
                </Text>
              </Cell>
              <Cell span={1}>
                <Text> </Text>
              </Cell>
            </Row>
            {clientSecrets.map((clientSecret, clientSecretIndex) => (
              <div key={clientSecret.clientSecretId} className={rowWrapper}>
                <Row verticalSpacing={3}>
                  <Cell span={5} position={'middle'} className={nameCell}>
                    <Text
                      className={wordWrap}
                      data-testid={`display-name-${clientSecretIndex}`}
                    >
                      {clientSecret.displayName}
                    </Text>
                    <input
                      name="client-secret-note"
                      value={clientSecret.note || ''}
                      className={noteTextArea}
                      placeholder={notePlaceholder}
                      maxLength={45}
                      data-testid={`note-input-${clientSecretIndex}`}
                      onChange={(e) =>
                        handleNoteChange(
                          (e.target as HTMLInputElement).value,
                          clientSecretIndex
                        )
                      }
                      onBlur={(e) =>
                        saveNoteChange(
                          e.target.value,
                          clientSecret.clientSecretId
                        )
                      }
                    />
                  </Cell>
                  <Cell span={3} position={'middle'}>
                    {clientSecret.lastUsedTime ? (
                      <Tooltip
                        content={formatISO9075(
                          new Date(clientSecret.lastUsedTime)
                        )}
                        position={'top'}
                      >
                        <Text
                          data-testid={`not-used-text-${clientSecretIndex}`}
                        >
                          {formatDistanceStrict(
                            new Date(clientSecret.lastUsedTime),
                            Date.now(),
                            {
                              addSuffix: true,
                            }
                          )}
                        </Text>
                      </Tooltip>
                    ) : (
                      <Text data-testid={`not-used-text-${clientSecretIndex}`}>
                        <Localization
                          messageKey={'text.not-used'}
                          fallback={'Not used'}
                        />
                      </Text>
                    )}
                  </Cell>
                  <Cell span={3} position={'middle'}>
                    <Text data-testid={`created-at-${clientSecretIndex}`}>
                      {new Date(clientSecret.createdAt).toLocaleDateString()}
                    </Text>
                  </Cell>
                  <Cell span={1} position={'middle'}>
                    <div className={actionWrapper}>
                      <Button
                        text
                        icon={DeleteIcon}
                        onClick={() =>
                          openDeleteDialog(clientSecret.clientSecretId)
                        }
                      >
                        <Localization
                          messageKey={'action.delete'}
                          fallback={'Delete'}
                        />
                      </Button>
                    </div>
                  </Cell>
                </Row>
              </div>
            ))}
          </Layout>
        )}
      </div>
      {newClientSecret && (
        <div className={width700}>
          <div className={rowWrapper}>
            <Alert
              className={width700}
              type="info"
              body={alertMessage}
              title="Pay attention"
            />
            <Row verticalSpacing={3}>
              <Cell span={5} position={'middle'} className={nameCell}>
                <Text className={wordWrap} data-testid="display-name-new">
                  {newClientSecret.displayName}
                </Text>
                <Tooltip content={copyContent} delay={500} position="right">
                  <CopyIcon
                    className={copyIconStyles}
                    onClick={() => handleCopy(newClientSecret.displayName)}
                  />
                </Tooltip>
                <input
                  name="client-secret-note"
                  value={newClientSecret.note || ''}
                  className={noteTextArea}
                  placeholder={notePlaceholder}
                  maxLength={45}
                  data-testid="note-input-new"
                  onChange={(e) =>
                    setNewClientSecret({
                      ...newClientSecret,
                      note: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    saveNoteChange(
                      e.target.value,
                      newClientSecret.clientSecretId
                    )
                  }
                />
              </Cell>
              <Cell span={3} position={'middle'}>
                <Text data-testid="not-used-text-new">
                  <Localization
                    messageKey={'text.not-used'}
                    fallback={'Not used'}
                  />
                </Text>
              </Cell>
              <Cell span={3} position={'middle'}>
                <Text>
                  {new Date(newClientSecret.createdAt).toLocaleDateString()}
                </Text>
              </Cell>
              <Cell span={1} position={'middle'}>
                <div className={actionWrapper}>
                  <Button
                    text
                    icon={DeleteIcon}
                    onClick={() =>
                      openDeleteDialog(newClientSecret.clientSecretId)
                    }
                  >
                    <Localization
                      messageKey={'action.delete'}
                      fallback={'Delete'}
                    />
                  </Button>
                </div>
              </Cell>
            </Row>
          </div>
        </div>
      )}
      <Button
        primary
        className={buttonStyles}
        onClick={handleGenerateSecret}
        testID={'add-secret-button'}
        icon={PlusIcon}
      >
        <Localization
          messageKey={'text.addsecret'}
          fallback={'Add client secret'}
        />
      </Button>
      {showDialog && (
        <Dialog onClose={() => setShowDialog(false)}>
          <H1>
            <Localization
              messageKey={'delete.client.secret'}
              fallback={'Delete Client Secret?'}
            />
          </H1>
          <span>
            <Localization
              messageKey={'delete.client.secret.body'}
              fallback={
                'You will no longer be able to use the corresponding Client Secret. Do you want to delete it?'
              }
            />
          </span>
          <DialogFooter>
            <Button primary action={handleDelete}>
              <Localization messageKey={'action.delete'} fallback={'Delete'} />
            </Button>
            <Button text action={() => setShowDialog(false)}>
              <Localization messageKey={'action.cancel'} fallback={'Cancel'} />
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  )
}
ClientSecrets.displayName = 'ClientSecrets'

const width700 = css`
  width: 700px;
`

const copyIconStyles = css`
  margin: -5px 10px;
  cursor: pointer;
  padding-top: 5px;
`

const nameCell = css`
  margin-left: 18px;
`

const wordWrap = css`
  word-break: break-all;
`

const noteTextArea = css`
  display: block;
  width: 100%;

  border: none;
  padding: 4px 0;
  font-size: 13px;
  color: ${primary.gray(400)};

  &:focus {
    outline: none;
  }
`
