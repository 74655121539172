import {
  Button,
  Dialog,
  Font,
  Form,
  FormGroup,
  InputField,
  primary,
  toast,
} from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import PlusIcon from 'phoenix-icons/dist/PlusIcon.js'
import React, { useState } from 'react'
import styled, { css } from 'react-emotion'
import { useNavigate } from 'react-router-dom'

import { ApplicationService } from '../../../api/api'
import { IApplicationResponse } from '../../../models/IApplication'
import { AuthenticationMethodForm } from './AuthenticationMethodForm/AuthenticationMethodForm'

export const CreateApplication: React.FC = () => {
  const navigate = useNavigate()
  const [showDialog, setShowDialog] = useState(false)

  const [inputFieldLabel] = useLocalization('text.name', 'Name')

  const handleSubmit = async (values) => {
    const { grantType, type } = JSON.parse(values.application)
    const applicationData = { name: values.name, grantType, type }

    setShowDialog(false)

    try {
      const response: IApplicationResponse = await ApplicationService.create(
        applicationData
      )
      navigate(
        `/setup/oauth2-apps/${response.application?.applicationId ?? ''}`
      )
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  return (
    <>
      <div className={buttonBorder}>
        <Button
          action={() => setShowDialog(true)}
          icon={PlusIcon}
          testID={'create-button'}
        >
          <Localization
            messageKey={'application.create'}
            fallback={'Create app integration'}
          />
        </Button>
      </div>
      {showDialog && (
        <Dialog
          onClose={() => setShowDialog(false)}
          width={665}
          initialFocus="#name"
          data-testid={'create-dialog'}
          header={
            <Font type={'h2'}>
              <Localization
                messageKey={'application.new'}
                fallback={'New OAuth2 application'}
              />
            </Font>
          }
        >
          <Form
            initialValues={{
              name: '',
              application: '',
            }}
            validateOnBlur={false}
            subscriptions={{ submitting: true, pristine: true }}
            onSubmit={handleSubmit}
          >
            <FormGroup>
              <AuthenticationMethodForm />
              <InputField name="name" label={inputFieldLabel} required />
              <DialogFooter>
                <Button primary type="submit" testID={'create-submit-button'}>
                  <ButtonText>
                    <Localization
                      messageKey={'action.create'}
                      fallback={'Create'}
                    />
                  </ButtonText>
                </Button>
                <Button text action={() => setShowDialog(false)}>
                  <ButtonText>
                    <Localization
                      messageKey={'action.cancel'}
                      fallback={'Cancel'}
                    />
                  </ButtonText>
                </Button>
              </DialogFooter>
            </FormGroup>
          </Form>
        </Dialog>
      )}
    </>
  )
}

const DialogFooter = styled('div')`
  display: flex;
  justify-content: end;
  padding-top: 8px;
`

const ButtonText = styled('div')`
  width: 80px;
`

const buttonBorder = css`
  border-bottom: 1px solid ${primary.gray(200)};
  padding: 16px 24px;
`
