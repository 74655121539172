import { Font, SelectField, Text } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import React, { Dispatch, SetStateAction } from 'react'
import { css } from 'react-emotion'

import { REFRESH_TOKEN_LIFETIME_VALUES } from '../../../constants/basicInformation'
import { ISettings } from '../../../models/ISettings'
import { cardWrapperStyles, solidLine } from '../../../utils/commonClasses'

interface RefreshTokenExpirationProps {
  absoluteDefaultValue: number | undefined
  inactivityDefaultValue: number | undefined
  isLoading: boolean
  setSettings: Dispatch<SetStateAction<ISettings | undefined>>
}

export const RefreshTokenExpiration: React.FC<RefreshTokenExpirationProps> = ({
  absoluteDefaultValue,
  inactivityDefaultValue,
  isLoading,
  setSettings,
}) => {
  const [days] = useLocalization('selectField.days', 'days')
  const [month] = useLocalization('selectField.month', 'month')
  const [months] = useLocalization('selectField.months', 'months')
  const [year] = useLocalization('selectField.year', 'year')
  const [years] = useLocalization('selectField.years', 'years')

  const absoluteLifetimeOptions = {
    [`6 ${months}`]: REFRESH_TOKEN_LIFETIME_VALUES.MONTHS_6,
    [`1 ${year}`]: REFRESH_TOKEN_LIFETIME_VALUES.YEAR_1,
    [`2 ${years}`]: REFRESH_TOKEN_LIFETIME_VALUES.YEARS_2,
  }

  const inactivityLifetimeOptions = {
    [`15 ${days}`]: REFRESH_TOKEN_LIFETIME_VALUES.DAYS_15,
    [`1 ${month}`]: REFRESH_TOKEN_LIFETIME_VALUES.MONTH_1,
    [`3 ${months}`]: REFRESH_TOKEN_LIFETIME_VALUES.MONTHS_3,
  }

  const updateAbsoluteLifetimeCallback = (settings, value) => ({
    ...settings,
    refreshTokenAbsoluteLifetime: value,
  })
  const updateInactivityLifetimeCallback = (settings, value) => ({
    ...settings,
    refreshTokenInactivityLifetime: value,
  })

  return (
    <div className={cardWrapperStyles}>
      <hr className={solidLine} />
      <div data-testid="settings-section">
        <div data-testid="absolute-lifetime-section">
          <div className={absoluteExpiration}>
            <Font type={'h4'}>
              <Localization
                messageKey={'absolute.expiration'}
                fallback={'Absolute expiration'}
              />
            </Font>
          </div>
          <Text.Small>
            <Localization
              messageKey={'absolute.refresh.token.will.expire.based.on'}
              fallback={
                'Refresh token will expire based on an absolute lifetime, after which the token can no longer be used.'
              }
            />
          </Text.Small>
          <div className={selectFieldStyle}>
            <SelectField
              className={customInputStyles}
              name={'absolute lifetime'}
              placeholder={'select expiration time'}
              options={Object.keys(absoluteLifetimeOptions)}
              mapOptionToValue={(option: string) =>
                absoluteLifetimeOptions[option]
              }
              defaultValue={absoluteDefaultValue}
              getLabelForValue={(value) =>
                setSettings((settings) =>
                  updateAbsoluteLifetimeCallback(settings, value)
                )
              }
              optionsMinWidth={500}
              showClearIcon={false}
              disabled={isLoading}
              disableInputBlurHandler={true}
            />
          </div>
        </div>

        <div data-testid="inactivity-lifetime-section">
          <div className={inactivityExpiration}>
            <Font type={'h4'}>
              <Localization
                messageKey={'inactivity.expiration'}
                fallback={'Inactivity expiration'}
              />
            </Font>
          </div>
          <Text.Small>
            <Localization
              messageKey={'inactivity.refresh.token.will.expire.based.on'}
              fallback={
                'Refresh token will expire based on an specified inactivity lifetime, after which the token can no longer be used.'
              }
            />
          </Text.Small>
          <div className={selectFieldStyle}>
            <SelectField
              className={customInputStyles}
              name={'inactivity lifetime'}
              placeholder={'select expiration time'}
              options={Object.keys(inactivityLifetimeOptions)}
              mapOptionToValue={(option: string) =>
                inactivityLifetimeOptions[option]
              }
              defaultValue={inactivityDefaultValue}
              getLabelForValue={(value) =>
                setSettings((settings) =>
                  updateInactivityLifetimeCallback(settings, value)
                )
              }
              optionsMinWidth={500}
              showClearIcon={false}
              disabled={isLoading}
              disableInputBlurHandler={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
RefreshTokenExpiration.displayName = 'RefreshTokenExpiration'

const customInputStyles = css`
  margin: 8px 0;
  width: 500px;
`

const inactivityExpiration = css`
  margin-top: 8px;
  margin-bottom: 2px;
`

const absoluteExpiration = css`
  margin-bottom: 2px;
`

const selectFieldStyle = css`
  margin-top: 20px;
  margin-bottom: 25px;
`
