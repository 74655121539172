// @ts-ignore
import { Alert, Button, Dialog, Font, H2, Text, toast } from '@phoenix/all'
// @ts-ignore
import { Card } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import PlusIcon from 'phoenix-icons/dist/PlusIcon.js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { css } from 'react-emotion'

import { PublicKeyService } from '../../../api/api'
import { IPublicKey, IPublicKeyResponse } from '../../../models/IPublicKey'
import {
  cardWrapperStyles,
  DialogFooter,
  infoStyles,
  titleStyles,
} from '../../../utils/commonClasses'
import { downloadZipFile } from '../../../utils/utils'
import { CustomInput } from '../BasicInformation/CustomInput/CustomInput'

export const PublicKeys: React.FC<{
  applicationId: string
  customerId: string
}> = React.memo(({ applicationId, customerId }) => {
  const inputFile = useRef<HTMLInputElement>(null)

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [publicKeys, setPublicKeys] = useState<Array<IPublicKey>>([])
  const [publicKeyIdToDelete, setPublicKeyIdToDelete] = useState<string | null>(
    null
  )
  const [publicKeyDeleteSuccessMessage] = useLocalization(
    'toast.public.private.key.successfully.deleted',
    'The public/private key has been deleted'
  )
  const [keyPairGenerateSuccessMessage] = useLocalization(
    'toast.public.private.key.successfully.generated',
    'The public/private key has been generated successfully'
  )
  const [publicKeyUploadSuccessMessage] = useLocalization(
    'toast.public.private.key.successfully.uploaded',
    'The public key successfully uploaded'
  )
  const [publicKeyUploadErrorMessage] = useLocalization(
    'toast.public.private.key.error.uploaded',
    'The public key is not in valid format. Check how to generate RSA keypair documentation, please'
  )

  const getPublicKey = useCallback(async () => {
    const response: IPublicKeyResponse = await PublicKeyService.list(
      applicationId
    )
    setPublicKeys(response.publicKeys)
  }, [applicationId])

  useEffect(() => {
    getPublicKey()
  }, [getPublicKey])

  const handleGeneratePublicKey = async () => {
    try {
      const response = await PublicKeyService.generate(applicationId)
      const blob = await response.blob()

      toast.success(keyPairGenerateSuccessMessage)

      downloadZipFile(blob)
      getPublicKey()
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  const handleAddPublicKey = async (e) => {
    const reader = new FileReader()
    reader.readAsText(e.target.files[0], 'UTF-8')

    reader.onloadend = async () => {
      const { result } = reader

      if (!result) return

      try {
        const { publicKeyId, userId } = await PublicKeyService.upload(
          applicationId,
          result
        )

        toast.success(publicKeyUploadSuccessMessage)
        setPublicKeys([{ publicKeyId, userId }])
      } catch (error: unknown) {
        toast.error((error as Error).message)
      }
    }

    reader.onerror = () => {
      toast.error(publicKeyUploadErrorMessage)
    }
  }

  const openDeleteDialog = (id) => {
    setShowDialog(true)
    setPublicKeyIdToDelete(id)
  }

  const handleDelete = async () => {
    setShowDialog(false)
    if (!publicKeyIdToDelete) return

    try {
      const { deleted } = await PublicKeyService.remove(
        applicationId,
        publicKeyIdToDelete
      )
      if (!deleted) return

      setPublicKeys([])

      toast.success(publicKeyDeleteSuccessMessage)
    } catch (error: unknown) {
      toast.error((error as Error).message)
    }
  }

  return (
    <div data-testid="public-keys-section">
      <Font type="h3" className={titleStyles}>
        <Localization messageKey={'public.keys'} fallback={'Public keys'} />
      </Font>
      <div className={cardWrapperStyles}>
        <Card>
          <Alert
            className={infoStyles}
            type="info"
            body={
              <Localization
                messageKey={'generate.rsa.keypair.info'}
                fallback="Generate an RSA keypair to sign and authenticate the JWT request made by your app or upload your own public key."
              />
            }
          />
          {publicKeys.length > 0 && (
            <Text.Small>
              <Localization
                messageKey={'public.key.id'}
                fallback={'Public key ID'}
              />
            </Text.Small>
          )}
          {publicKeys.map(({ publicKeyId, userId }) => (
            <div key={publicKeyId}>
              <div className={fieldWrapper} id={'public-key-input'}>
                <CustomInput
                  disabled
                  value={publicKeyId}
                  showCopyIcon={false}
                  handleDelete={() => openDeleteDialog(publicKeyId)}
                />
              </div>
              <div className={fieldWrapper}>
                <Text.Small>
                  <Localization
                    messageKey={'public.key.customerId'}
                    fallback="Customer ID"
                  />
                </Text.Small>
                <CustomInput disabled value={customerId} showCopyIcon={true} />
              </div>
              <div className={fieldWrapper}>
                <Text.Small>
                  <Localization
                    messageKey={'public.key.userId'}
                    fallback="User ID"
                  />
                </Text.Small>
                <CustomInput disabled value={userId} showCopyIcon={true} />
              </div>
            </div>
          ))}
          <div className={buttonsGroup}>
            <input
              type="file"
              accept=".crt"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleAddPublicKey}
            />
            <Button
              primary
              onClick={() => inputFile?.current?.click()}
              testID={'add-public-key'}
              disabled={publicKeys.length > 0}
              icon={PlusIcon}
            >
              <Localization
                messageKey={'add.public.key'}
                fallback={'Add public key'}
              />
            </Button>
            <Text>
              <Localization messageKey={'or'} fallback={'Or'} />
            </Text>
            <Button
              secondary
              className={generateButton}
              onClick={handleGeneratePublicKey}
              testID={'generate-public-key'}
              disabled={publicKeys.length > 0}
              icon={PlusIcon}
            >
              <Localization
                messageKey={'generate.public.private.keypair'}
                fallback={'Generate a public/private keypair'}
              />
            </Button>
          </div>
          {showDialog && (
            <Dialog onClose={() => setShowDialog(false)}>
              <H2>
                <Localization
                  messageKey={'delete.public.private.keypair'}
                  fallback={'Delete Public Key?'}
                />
              </H2>
              <span>
                <Localization
                  messageKey={'delete.public.private.keypair.body'}
                  fallback={
                    'You will no longer be able to use the corresponding public key. Do you want to delete it?'
                  }
                />
              </span>
              <DialogFooter>
                <Button primary action={handleDelete}>
                  <Localization
                    messageKey={'action.delete'}
                    fallback={'Delete'}
                  />
                </Button>
                <Button text action={() => setShowDialog(false)}>
                  <Localization
                    messageKey={'action.cancel'}
                    fallback={'Cancel'}
                  />
                </Button>
              </DialogFooter>
            </Dialog>
          )}
        </Card>
      </div>
    </div>
  )
})

PublicKeys.displayName = 'PublicKeys'

const buttonsGroup = css`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 15px;
`

const fieldWrapper = css`
  margin-bottom: 6px;
`

const generateButton = css`
  margin-left: 0;
`
