import { Button, Font, Text } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import PlusSmallIcon from 'phoenix-icons/dist/PlusSmallIcon.js'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { RedirectUrisService } from '../../../../api/api'
import { IRedirectUrisResponse } from '../../../../models/IRedirectUri'
import { buttonStyles, infoStyles } from '../../../../utils/commonClasses'
import { isUrl } from '../../../../utils/utils'
import { CustomInput } from '../CustomInput/CustomInput'

interface RedirectUrisProps {
  applicationId: string
  onRedirectUrisChange: (uris: IUris[]) => void
}

export interface IUris {
  uri: string
  valid: boolean
}

export const RedirectUris: React.FC<RedirectUrisProps> = ({
  applicationId,
  onRedirectUrisChange,
}) => {
  const [redirectUris, setRedirectUris] = useState<IUris[]>([
    {
      uri: '',
      valid: true,
    },
  ])

  const [redirectURLHelp] = useLocalization(
    'redirect.uri.help',
    'Users will be redirected to this path after they have authenticated with workfront. The path will be appended with the authorization code for access, and must have a protocol. It can’t contain URL fragments, relative paths, or wildcards, and can’t be a public IP address.'
  )
  const [invalidURlError] = useLocalization(
    'invalid.url.error',
    'Please type a valid URI. Example: https://yourdomain.com/redirectUrl'
  )

  const updateRedirectUris = useCallback(
    (uris: IUris[]) => {
      setRedirectUris(uris)
      onRedirectUrisChange(uris)
    },
    [setRedirectUris, onRedirectUrisChange]
  )

  const getApplicationRedirectUris = useCallback(async () => {
    const response: IRedirectUrisResponse = await RedirectUrisService.get(
      applicationId
    )

    if (response.allowedRedirectUris.length === 0) return

    updateRedirectUris(
      response.allowedRedirectUris.map((uri) => ({ uri, valid: true }))
    )
  }, [applicationId, updateRedirectUris])

  useEffect(() => {
    getApplicationRedirectUris()
  }, [getApplicationRedirectUris])

  const handleChange = (i) => (event: ChangeEvent<HTMLInputElement>) => {
    const currentValues: IUris[] = [...redirectUris]
    currentValues[i] = {
      uri: event.target.value,
      valid: isUrl(event.target.value),
    }
    updateRedirectUris(currentValues)
  }

  const handleAddInput = () => {
    setRedirectUris([...redirectUris, { uri: '', valid: true }])
  }

  const handleRemove = (i) => () => {
    const currentValues = [...redirectUris]
    currentValues.splice(i, 1)
    updateRedirectUris(currentValues)
  }

  return (
    <div data-testid="redirect-uri-section">
      <Font type="h4">
        <Localization messageKey={'redirect.uris'} fallback={'Redirect URls'} />
      </Font>
      <div className={infoStyles}>
        <Text.Small>{redirectURLHelp}</Text.Small>
      </div>
      {redirectUris.map((el, i) => (
        <CustomInput
          value={el.uri}
          key={i}
          handleDelete={redirectUris.length !== 1 ? handleRemove(i) : undefined}
          onChange={handleChange(i)}
          disabled={false}
          error={el.valid ? '' : invalidURlError}
        />
      ))}
      <Button text className={buttonStyles} onClick={handleAddInput}>
        <PlusSmallIcon />
        <Localization
          messageKey={'text.add.another'}
          fallback={' Add another'}
        />
      </Button>
    </div>
  )
}

RedirectUris.displayName = 'RedirectUris'
