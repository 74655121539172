// @ts-ignore
import { Card, Font } from '@phoenix/all'
import React, { Dispatch, SetStateAction } from 'react'

import { IApplication } from '../../../models/IApplication'
import { ISettings } from '../../../models/ISettings'
import { cardWrapperStyles, titleStyles } from '../../../utils/commonClasses'
import { DeveloperInformation } from '../AdditionalInformation/DeveloperInformation/DeveloperInformation'
import { Logo } from '../AdditionalInformation/Logo/Logo'
import { MainInformation } from '../AdditionalInformation/MainInformation/MainInformation'
import { RefreshTokenSettings } from '../RefreshTokenSettings'

interface UserAuthenticationProps {
  applicationData: IApplication
  additionalInfo: string
  settings: ISettings | undefined
  setSettings: Dispatch<SetStateAction<ISettings | undefined>>
  onLogoUpload: (blob: Blob[]) => void
  onLogoRemove: () => void
  isJwtFlow: boolean
}

export const UserAuthentication: React.FC<UserAuthenticationProps> = ({
  applicationData,
  additionalInfo,
  settings,
  setSettings,
  onLogoUpload,
  onLogoRemove,
  isJwtFlow,
}) => {
  return (
    <>
      {applicationData && (
        <div>
          <div>
            <RefreshTokenSettings
              applicationId={applicationData.applicationId}
              settings={settings}
              setSettings={setSettings}
              isPkceFlow={false}
            />
          </div>
          <div className={cardWrapperStyles}>
            <Font type="h3" className={titleStyles}>
              {additionalInfo}
            </Font>
            <Card>
              <Logo
                applicationId={applicationData.applicationId}
                logoPath={applicationData.logoPath}
                onLogoUpload={onLogoUpload}
                onLogoRemove={onLogoRemove}
              />
              <MainInformation />
              <DeveloperInformation showMoreInfo={!isJwtFlow} />
            </Card>
          </div>
        </div>
      )}
    </>
  )
}
