// @ts-ignore
import { FormGroup, InputField, TextareaField } from '@phoenix/all'
import { useLocalization } from '@workfront/localize-react'
import React from 'react'

import { inputStyles } from '../../../../utils/commonClasses'

export const MainInformation: React.FC = React.memo(() => {
  const [nameLabel] = useLocalization('text.name', 'Name')
  const [descriptionLabel] = useLocalization('text.description', 'Description')

  return (
    <div>
      <FormGroup>
        <div>
          <InputField
            className={inputStyles}
            name="name"
            label={nameLabel}
            required
          />
          <TextareaField
            className={inputStyles}
            name="description"
            label={descriptionLabel}
          />
        </div>
      </FormGroup>
    </div>
  )
})
MainInformation.displayName = 'MainInformation'
