import { Button, RadioField, RadioGroup } from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import React from 'react'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'

import { apps } from '../../../../constants/authMethodsInfo'
import { grayColor400, textStyle } from '../../../../utils/commonClasses'

export const AuthenticationMethodForm: React.FC = () => {
  const [authenticationMethodLabel] = useLocalization(
    'authentication.method',
    'Authentication method'
  )

  const getAppList = (apps) => {
    return apps
  }

  return (
    <>
      <div>
        <div className={textStyle}>
          <Localization
            messageKey={'authentication.method.select.hint'}
            fallback={
              'Select an authentication method and enter a name for your app.'
            }
          />
        </div>
      </div>
      <div>
        <div className={textStyle}>
          <Localization
            messageKey={'authentication.method.can.be.changed'}
            fallback={'You can change the name later in General Settings.'}
          />
        </div>
      </div>
      <div
        className={authMethodList}
        data-testid="authorization-method-section"
      >
        <RadioGroup legend={authenticationMethodLabel}>
          {getAppList(apps).map((app) => (
            <div className={authMethodItem} key={app.labelMsgKey}>
              <div className={radioField}>
                <RadioField
                  label={
                    <div className={textStyle}>
                      <Localization
                        messageKey={app.labelMsgKey}
                        fallback={app.labelFallback}
                      />
                    </div>
                  }
                  name="application"
                  value={JSON.stringify({
                    grantType: app.grantType,
                    type: app.type,
                  })}
                  data-testid={app.dataTestId}
                  required
                />
              </div>
              <div>
                <ul className={infoList}>
                  {app.infoList.map((info) => (
                    <li key={info.infoMsgKey} className={infoListItem}>
                      <div className={infoTextStyle}>
                        <Localization
                          messageKey={info.infoMsgKey}
                          fallback={info.infoFallback}
                        />
                      </div>
                      {info.readMoreLink && (
                        <Button text className={readMore}>
                          <Link to={info.readMoreLink}>
                            <Localization
                              messageKey={'read.more'}
                              fallback={'Read more'}
                            />
                          </Link>
                        </Button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </RadioGroup>
      </div>
    </>
  )
}

const infoList = css`
  ${grayColor400};
  list-style: initial;
  padding-left: 45px;
`

const infoListItem = css`
  padding-bottom: 8px;
`

const authMethodList = css`
  margin-top: 30px;
`

const authMethodItem = css`
  padding-bottom: 22px;
`

const readMore = css`
  height: 21px;
`

const radioField = css`
  padding-bottom: 5px;
`

const infoTextStyle = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #808080;
`
