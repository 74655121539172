import { IApiError } from '../models/IApi'
export const responseHandler = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOkResponse: (response: Response): Promise<any> => {
    return response.text().then((data) => data && JSON.parse(data))
  },

  handleErrorResponse: (response: Response): Promise<IApiError> => {
    return response.json().then((err) => {
      throw err
    })
  },
}
