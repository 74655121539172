// @ts-expect-error the package @types/wf-mfe__api does not exist
import { wfetch } from '@wf-mfe/api'

import {
  IApplication,
  IApplicationDeleteResponse,
  IApplicationResponse,
  IApplicationsResponse,
  IApplicationUpdateResponse,
  IPostApplicationParams,
} from '../models/IApplication'
import {
  IClientSecretResponse,
  IClientSecretsResponse,
  IPatchClientSecretParams,
} from '../models/IClientSecret'
import { ILogoResponse } from '../models/ILogo'
import { IPublicKey, IPublicKeyResponse } from '../models/IPublicKey'
import { IRedirectUrisResponse } from '../models/IRedirectUri'
import { ISettingsResponse } from '../models/ISettings'
import { responseHandler } from './ResponseHandler'

const BASE_URL = '/integrations/oauth2-apps/api'
//const BASE_URL = 'http://localhost:3005/api'

const fetchClient = (url?: string, options?: RequestInit) =>
  wfetch(
    `${BASE_URL}/v1/applications${url}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    },
    responseHandler
  )

const ApiService = {
  query(qs) {
    return fetchClient(`?${new URLSearchParams(qs)}`)
  },

  get(resource: string) {
    return fetchClient(resource)
  },

  post(resource: string, params) {
    return fetchClient(resource, {
      method: 'POST',
      body: JSON.stringify(params),
    })
  },

  put(resource: string, params) {
    return fetchClient(resource, {
      method: 'PUT',
      body: JSON.stringify(params),
    })
  },

  patch(resource: string, params) {
    return fetchClient(resource, {
      method: 'PATCH',
      body: JSON.stringify(params),
    })
  },

  remove(resource: string) {
    return fetchClient(resource, {
      method: 'DELETE',
    })
  },
}

export const ApplicationService = {
  get(applicationId: string): Promise<IApplicationResponse> {
    return ApiService.get(`/${applicationId}`)
  },

  create(application: IPostApplicationParams): Promise<IApplicationResponse> {
    return ApiService.post('', application)
  },

  update(application: IApplication): Promise<IApplicationUpdateResponse> {
    return ApiService.put(`/${application.applicationId}`, application)
  },

  list(skip: number, limit: number): Promise<IApplicationsResponse> {
    return ApiService.query({
      skip,
      limit,
    })
  },

  remove(applicationId: string): Promise<IApplicationDeleteResponse> {
    return ApiService.remove(`/${applicationId}`)
  },
}

export const ClientSecretService = {
  get(
    applicationId: string,
    skip = 0,
    limit = 100
  ): Promise<IClientSecretsResponse> {
    return ApiService.get(
      `/${applicationId}/client-secrets?${new URLSearchParams({
        skip: String(skip),
        limit: String(limit),
      })}`
    )
  },

  add(applicationId: string): Promise<IClientSecretResponse> {
    return ApiService.post(`/${applicationId}/client-secrets`, {})
  },

  update(
    applicationId: string,
    clientSecretId: string,
    params: IPatchClientSecretParams
  ): Promise<{ updated: boolean }> {
    return ApiService.patch(
      `/${applicationId}/client-secrets/${clientSecretId}`,
      params
    )
  },

  remove(
    applicationId: string,
    clientSecretId: string
  ): Promise<{ deleted: boolean }> {
    return ApiService.remove(
      `/${applicationId}/client-secrets/${clientSecretId}`
    )
  },
}

export const RedirectUrisService = {
  get(applicationId: string): Promise<IRedirectUrisResponse> {
    return ApiService.get(`/${applicationId}/redirect-uris`)
  },

  post(
    applicationId: string,
    allowedRedirectUris: string[]
  ): Promise<IRedirectUrisResponse> {
    return ApiService.post(`/${applicationId}/redirect-uris`, {
      allowedRedirectUris,
    })
  },
}

export const PublicKeyService = {
  list(applicationId: string): Promise<IPublicKeyResponse> {
    return ApiService.get(`/${applicationId}/public-keys`)
  },

  remove(
    applicationId: string,
    publicKeyId: string
  ): Promise<{ deleted: boolean }> {
    return ApiService.remove(`/${applicationId}/public-keys/${publicKeyId}`)
  },

  generate(applicationId: string): Promise<Response> {
    return wfetch(
      `${BASE_URL}/v1/applications/${applicationId}/public-keys/generate`
    ).then(
      (response) => response,
      (error) => responseHandler.handleErrorResponse(error)
    )
  },

  upload(
    applicationId: string,
    publicKey: string | ArrayBuffer
  ): Promise<IPublicKey> {
    return ApiService.post(`/${applicationId}/public-keys`, { publicKey })
  },
}

export const LogoService = {
  create(applicationId: string, data: FormData): Promise<ILogoResponse> {
    return fetchClient(`/${applicationId}/logo`, {
      method: 'POST',
      body: data,
      headers: {},
    })
  },

  remove(applicationId: string): Promise<{ deleted: boolean }> {
    return ApiService.remove(`/${applicationId}/logo`)
  },
}

export const SettingsService = {
  get(applicationId: string): Promise<ISettingsResponse> {
    return ApiService.get(`/${applicationId}/settings`)
  },

  update(
    applicationId: string,
    absolute: number | undefined,
    inactivity: number | undefined,
    isPkceFlow: boolean | undefined,
    shouldRotateRefreshToken?: boolean | undefined
  ): Promise<ISettingsResponse> {
    return ApiService.post(`/${applicationId}/settings`, {
      shouldRotateRefreshToken: isPkceFlow || !!shouldRotateRefreshToken,
      refreshTokenAbsoluteLifetime: absolute,
      refreshTokenInactivityLifetime: inactivity,
    })
  },
}
