import { primary } from '@phoenix/all'
import styled, { css } from 'react-emotion'

export const actionWrapper = css`
  display: flex;
  visibility: hidden;
  opacity: 0;
  justify-content: end;
  padding-right: 30px;
  transition: visibility 0s, opacity 0.2s linear;
`

export const rowWrapper = css`
  border: 1px solid ${primary.gray(200)};
  margin: 10px 0;
  border-radius: 3px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 2px 4px #00000034;
  }

  &:hover .${actionWrapper} {
    visibility: visible;
    opacity: 1;
  }
`

export const fontWeightBold = css`
  font-weight: bold;
`

export const grayColor400 = css`
  color: ${primary.gray(400)};
`

export const grayColor500 = css`
  color: #595f69;
  font-weight: 700;
`

export const inputStyles = css`
  width: 500px;
`

export const infoStyles = css`
  margin-bottom: 15px;
  width: 566px;
`

export const DialogFooter = styled('div')`
  border-top: 1px solid ${primary.gray(200)};
  padding-top: 24px;
  margin-top: 50px;
`

export const buttonStyles = css`
  display: block;
  margin: 8px 0;
`

export const solidLine = css`
  height: 0.5px;
  left: 18px;
  right: 18px;
  top: 137px;
  background: #e0e1e3;
  margin-top: 28px;
  margin-bottom: 28px;
`

export const marginBottomStyles = css`
  margin-bottom: 20px;
`
export const cardWrapperStyles = css`
  max-width: 770px;
  min-width: 680px;
`

export const titleStyles = css`
  margin-top: 62px;
  margin-bottom: 20px;
`

export const textStyle = css`
  font-style: normal;
  font-weight: 1;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
`
