export const grantTypes = {
  JWT_FLOW: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
  AUTHORIZATION_CODE_FLOW: 'authorization_code',
}

export const appTypes = {
  MACHINE_TO_MACHINE: 'machine_to_machine',
  SINGLE_PAGE_WEB: 'single_page_web',
  WEB: 'web',
}

export const appTypesMsgKeys = {
  machine_to_machine: {
    msgKey: 'machine.to.machine.application',
    fallback: 'Machine to Machine Application',
  },
  web: {
    msgKey: 'web.application',
    fallback: 'Web Application',
  },
  single_page_web: {
    msgKey: 'single.page.web.application',
    fallback: 'Single Page Web Application',
  },
}

export const apps = [
  {
    type: appTypes.MACHINE_TO_MACHINE,
    labelMsgKey: 'machine.to.machine.application',
    labelFallback: 'Machine to Machine Application',
    grantType: grantTypes.JWT_FLOW,
    dataTestId: 'jwt-flow-radio-button',
    infoList: [
      {
        infoMsgKey: 'machine.to.machine.application.best.for',
        infoFallback:
          'Best for CLIs, daemons, or scripts running on your server (e.g.: Shell, Python)',
        readMoreLink: null,
      },
      {
        infoMsgKey: 'machine.to.machine.application.requires',
        infoFallback:
          'Authentication through JSON Web Token with public/private key-pair encoding.',
        readMoreLink: null,
      },
    ],
  },
  {
    type: appTypes.SINGLE_PAGE_WEB,
    labelMsgKey: 'single.page.web.application',
    labelFallback: 'Single Page Web Application',
    grantType: grantTypes.AUTHORIZATION_CODE_FLOW,
    dataTestId: 'authorization-code-flow-pkce-radio-button',
    infoList: [
      {
        infoMsgKey: 'single.page.web.application.best.for',
        infoFallback:
          'Best for mobile or single-page web applications (e.g.: Javascript, Angular, React, Vue).',
        readMoreLink: null,
      },
      {
        infoMsgKey: 'single.page.web.application.requires',
        infoFallback:
          'Authentication through OAuth 2.0 Authorization Code flow with Proof Key for Code Exchange (PKCE).',
        readMoreLink: null,
      },
    ],
  },
  {
    type: appTypes.WEB,
    labelMsgKey: 'web.application',
    labelFallback: 'Web Application',
    grantType: grantTypes.AUTHORIZATION_CODE_FLOW,
    dataTestId: 'authorization-code-flow-radio-button',
    infoList: [
      {
        infoMsgKey: 'web.application.best.for',
        infoFallback:
          'Best for server-side applications that handle credentials and tokens on the server (e.g.: Go, Java, ASP.Net, Node.js, PHP).',
        readMoreLink: null,
      },
      {
        infoMsgKey: 'web.application.requires',
        infoFallback:
          'Authentication through OAuth 2.0 Authorization Code flow.',
        readMoreLink: null,
      },
    ],
  },
]
