const MESSAGES_KEYS = [
  'absolute.expiration',
  'absolute.refresh.token.expiration',
  'absolute.refresh.token.will.expire.based.on',
  'action.cancel',
  'action.create',
  'action.delete',
  'action.edit',
  'action.save',
  'alert.copyclientsecret',
  'app.not.found',
  'app.successfully.deleted',
  'app.successfully.updated',
  'applications',
  'application.create',
  'application.delete',
  'application.delete.confirm',
  'application.new',
  'authentication.method',
  'authentication.method.can.be.changed',
  'authentication.method.select.hint',
  'copy.the.client.ID.for',
  'delete.public.private.keypair',
  'drag.and.zoom.to.crop',

  'enable.this.option.to.issue.a.new.refresh.token',

  'generate.rsa.keypair.info',

  'inactivity.expiration',
  'inactivity.refresh.token.expiration',
  'inactivity.refresh.token.will.expire.based.on',
  'invalid.url.error',
  'or',

  'placeholder.clientsecret.notes',
  'public.key.id',
  'public.keys',

  'read.more',
  'redirect.uris',
  'redirect.uri.help',
  'rotate.refresh.token',
  'rotate.refresh.token.everytime.it.is.used',
  'securely.integrate.an.application',
  'setup.section',
  'selectField.month',
  'selectField.months',
  'selectField.year',
  'selectField.years',

  'text.addsecret',
  'text.applogoalt',
  'text.applogo.title',
  'text.basicinfo',
  'text.clientid',
  'text.clientsecret',
  'text.creationdate',
  'text.description',
  'text.developer.email.address',
  'text.developer.name',
  'text.helplink.desc',
  'text.name',
  'text.no.access',
  'text.privacypolicy.url',
  'text.refresh.token.settings',

  'toast.clientsecret.successfully.deleted',
  'toast.clientsecret.successfully.generated',
  'toast.copied.successfully',
  'toast.public.private.key.error.uploaded',
  'toast.public.private.key.successfully.generated',
  'toast.public.private.key.successfully.uploaded',
  'tooltip.copytoclipboard',
  'tooltip.delete',
  'type',

  'upload.application.logo',

  'validation.email',
  'validation.redirect',
  'validation.url',
  'field.required.error',
]

export default MESSAGES_KEYS
