// @ts-ignore
import { Card, Font } from '@phoenix/all'
import React from 'react'

import { IApplication } from '../../../models/IApplication'
import { cardWrapperStyles, titleStyles } from '../../../utils/commonClasses'
import { MainInformation } from '../AdditionalInformation/MainInformation/MainInformation'
import { PublicKeys } from '../PublicKeys/PublicKeys'

interface ServiceUserAuthenticationProps {
  applicationData: IApplication
  additionalInfo: string
}
export const ServiceUserAuthentication: React.FC<ServiceUserAuthenticationProps> = ({
  applicationData,
  additionalInfo,
}) => {
  return (
    <>
      {applicationData && (
        <div>
          <PublicKeys
            applicationId={applicationData.applicationId}
            customerId={applicationData.customerId}
          />
          <Font type="h3" className={titleStyles}>
            {additionalInfo}
          </Font>
          <div className={cardWrapperStyles}>
            <Card>
              <MainInformation />
            </Card>
          </div>
        </div>
      )}
    </>
  )
}
