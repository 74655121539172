import { FormGroup, InputField } from '@phoenix/all'
import { useLocalization } from '@workfront/localize-react'
import React from 'react'
import { css } from 'react-emotion'

import { isEmailAddress, isUrl } from '../../../../utils/utils'

interface DeveloperInformationProps {
  showMoreInfo: boolean
}

export const DeveloperInformation: React.FC<DeveloperInformationProps> = React.memo(
  () => {
    const [devNameLabel] = useLocalization(
      'text.developer.name',
      'Developer name'
    )
    const [devEmailAddressLabel] = useLocalization(
      'text.developer.email.address',
      'Developer email address'
    )
    const [emailValidationMessage] = useLocalization(
      'validation.email',
      'This field must be a valid email address'
    )
    const [privacyPolicyUrl] = useLocalization(
      'text.privacypolicy.url',
      'Privacy policy URL'
    )
    const [urlValidationMessage] = useLocalization(
      'validation.url',
      'This field must be a valid url address'
    )

    return (
      <div>
        <FormGroup>
          <div>
            <InputField
              className={inputStyle}
              name="developerName"
              label={devNameLabel}
            />
            <InputField
              className={inputStyle}
              name="developerEmailAddress"
              label={devEmailAddressLabel}
              validate={(email) =>
                email && !isEmailAddress(email) ? emailValidationMessage : null
              }
            />
            <InputField
              className={inputStyle}
              name="privacyPolicyUrl"
              label={privacyPolicyUrl}
              validate={(url) =>
                url && !isUrl(url) ? urlValidationMessage : null
              }
            />
          </div>
        </FormGroup>
      </div>
    )
  }
)
DeveloperInformation.displayName = 'AdditionalInformation'

const inputStyle = css`
  width: 500px;
`
