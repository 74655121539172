import { ImageObject } from '../models/ILogo'
import { positionMapping } from './constants'

export const maskClientSecret = (clientSecret: string): string => {
  const lastChars = clientSecret.substr(clientSecret.length - 4)
  return `****************${lastChars}`
}

export const isUrl = (str: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

export const isEmailAddress = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(email)
}

export const imgObjFromId = (
  applicationId: string,
  logoURL: string
): ImageObject => ({
  ID: applicationId,
  fileExtension: 'png',
  position: positionMapping[1],
  shouldTile: false,
  shouldFillScreen: true,
  URL: logoURL,
})

export const downloadZipFile = (blob: Blob): void => {
  const url = window.URL.createObjectURL(blob)
  const linkElement = document.createElement('a')
  linkElement.setAttribute('href', url)
  linkElement.setAttribute('download', 'config.zip')
  document.body.appendChild(linkElement)

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  })

  linkElement.dispatchEvent(clickEvent)
  linkElement.remove()
  window.URL.revokeObjectURL(url)
}
