/*------------- Settings ---------------*/
const REFRESH_TOKEN_LIFETIME_VALUES = {
  DAYS_15: 15 * 24 * 3600 * 1000,
  MONTH_1: 31 * 24 * 3600 * 1000,
  MONTHS_3: 3 * 31 * 24 * 3600 * 1000,
  MONTHS_6: 183 * 24 * 3600 * 1000, // 6 month in milliseconds
  YEAR_1: 365 * 24 * 3600 * 1000, // 1 year in milliseconds
  YEARS_2: 730 * 24 * 3600 * 1000, // 2 years in milliseconds
}

/*------------- Settings end ---------------*/

export { REFRESH_TOKEN_LIFETIME_VALUES }
