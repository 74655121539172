// @ts-ignore
import { Card, Font, Text } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
import React from 'react'

import { cardWrapperStyles, solidLine } from '../../../utils/commonClasses'
import { ClientSecrets } from './ClientSecrets/ClientSecrets'
import { PkceNote } from './ClientSecrets/PkceNote'
import { CustomInput } from './CustomInput/CustomInput'
import { IUris, RedirectUris } from './RedirectURIs/RedirectURIs'

interface BasicInformationProps {
  applicationId: string
  clientId: string
  setRedirectUris: (urls: IUris[]) => void
  isJwtFlow: boolean
  isPkceFlow: boolean
}

export const BasicInformation: React.FC<BasicInformationProps> = React.memo(
  ({ applicationId, clientId, setRedirectUris, isJwtFlow, isPkceFlow }) => {
    return (
      <>
        <Font type="h3" style={{ marginBottom: '20px' }}>
          <Localization
            messageKey={'text.basicinfo'}
            fallback={'Basic information'}
          />
        </Font>
        <div className={cardWrapperStyles}>
          <Card>
            <div>
              <Font type="h4">
                <Localization
                  messageKey={'text.clientid'}
                  fallback={'Client ID'}
                />
              </Font>
              <Text.Small>
                <Localization
                  messageKey={'copy.the.client.ID.for'}
                  fallback={'Copy the client ID for using your applications'}
                />
              </Text.Small>
              <CustomInput value={clientId} disabled />
            </div>
            <hr className={solidLine} />
            {!isPkceFlow ? (
              <ClientSecrets applicationId={applicationId} />
            ) : (
              <PkceNote />
            )}
            {!isJwtFlow ? (
              <>
                <hr className={solidLine} />
                <RedirectUris
                  applicationId={applicationId}
                  onRedirectUrisChange={setRedirectUris}
                />
              </>
            ) : null}
          </Card>
        </div>
      </>
    )
  }
)
BasicInformation.displayName = 'BasicInformation'
