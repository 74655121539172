import { A, Tooltip } from '@phoenix/all'
import { SetupLayout } from '@setup/components'
import {
  LoadLocalizations,
  Localization,
  LocalizationProvider,
} from '@workfront/localize-react'
import InfoIcon from 'phoenix-icons/dist/InfoIcon.js'
import SystemExtraLargeHeroIcon from 'phoenix-icons/dist/SystemExtraLargeHeroIcon.js'
import * as React from 'react'

import MESSAGE_KEYS from '../constants/messageKeys'

export const localizationClientFactory = (): {
  namespace: string | string[]
} => ({
  namespace: ['phoenix', 'oauth2'],
})

export interface OAuth2PageContainerProps {
  pageTitle: JSX.Element
  children: JSX.Element
}

const OAuth2PageContainer: React.FC<OAuth2PageContainerProps> = (
  props: OAuth2PageContainerProps
) => {
  const { children, pageTitle } = props
  const languageIdentifier = 'en';//TODO: get language identifier from current locale, and ensure it's supported by experienceLeague

  return (
    <LocalizationProvider clientFactory={localizationClientFactory}>
      <LoadLocalizations messageKeys={MESSAGE_KEYS}>
        <SetupLayout
          headerIcon={<SystemExtraLargeHeroIcon />}
          title={
            <div>
              {pageTitle}
              <Tooltip
                content={
                  <Localization
                    messageKey={'securely.integrate.an.application'}
                    fallback={
                      'Securely integrate an application with Workfront'
                    }
                  />
                }
                style={{ marginLeft: '19px', verticalAlign: 'middle' }}
                light
                delay={500}
                position="right"
              >
                <A
                  href={`https://experienceleague.adobe.com/${languageIdentifier}/docs/workfront/using/administration-and-setup/configure-integrations/manage-custom-oauth2-apps`}
                  target="_blank"
                >
                  <InfoIcon color={'#454B57'} />
                </A>
              </Tooltip>
            </div>
          }
          section={
            <Localization messageKey={'setup.section'} fallback={'System'} />
          }
        >
          {children}
        </SetupLayout>
      </LoadLocalizations>
    </LocalizationProvider>
  )
}

export default OAuth2PageContainer
