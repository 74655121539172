import {
  Alert,
  Avatar,
  Button,
  Cell,
  Dialog,
  Font,
  Layout,
  primary,
  Row,
  Text,
  toast,
} from '@phoenix/all'
import { Localization, useLocalization } from '@workfront/localize-react'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import EditIcon from 'phoenix-icons/dist/EditIcon.js'
import React, { useEffect, useState } from 'react'
import { css } from 'react-emotion'
import { Link } from 'react-router-dom'

import { ApplicationService } from '../../../api/api'
import { appTypesMsgKeys } from '../../../constants/authMethodsInfo'
import { IApplication } from '../../../models/IApplication'

export const ApplicationsList: React.FC = () => {
  const [applicationList, setApplicationList] = useState<IApplication[]>([])
  const [isApplicationListLoaded, setIsApplicationListLoaded] = useState(false)
  const [isShowDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [applicationIdToDelete, setApplicationIdToDelete] = useState<
    string | null
  >()

  const [emptyListBody] = useLocalization(
    'empty.list.body',
    'Click the Create app integration button to get started.'
  )

  const [emptyListTitle] = useLocalization(
    'empty.list.title',
    'There aren’t any apps yet'
  )

  const [deleteApplicationText] = useLocalization(
    'application.delete',
    'Delete Application'
  )

  const [deleteApplicationSuccessText] = useLocalization(
    'app.successfully.deleted',
    'OAuth2 Application successfully deleted'
  )

  const [applicationLogoAlt] = useLocalization('text.applogoalt', 'logo')

  const getApplicationsList = async () => {
    const response = await ApplicationService.list(0, 50)
    setApplicationList(response.applications)
    setIsApplicationListLoaded(true)
  }

  const handleApplicationDelete = async () => {
    if (!applicationIdToDelete) return

    try {
      const successfully = await ApplicationService.remove(
        applicationIdToDelete
      )
      closeDeleteDialog()

      if (!successfully.deleted) return

      toast.success(deleteApplicationSuccessText)
      setApplicationList(
        applicationList.filter(
          (application) => application.applicationId !== applicationIdToDelete
        )
      )
    } catch (error: unknown) {
      // TODO - there are 2 options, either backend should send message key, or localize message before returning
      toast.error((error as Error).message)
    } finally {
      closeDeleteDialog()
    }
  }

  const openDeleteDialog = (applicationId) => {
    setApplicationIdToDelete(applicationId)
    setShowDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    setApplicationIdToDelete(null)
    setShowDeleteDialog(false)
  }

  useEffect(() => {
    getApplicationsList()
  }, [])

  return (
    <div>
      <Layout fullBleed={true}>
        {isApplicationListLoaded && applicationList.length === 0 ? (
          <Alert type="info" body={emptyListBody} title={emptyListTitle} />
        ) : (
          <>
            <Row>
              <Cell span={3} className={nameCell}>
                <Font type="h4">
                  <Localization messageKey={'text.name'} fallback={'Name'} />
                </Font>
              </Cell>
              <Cell span={3}>
                <Font type="h4">
                  <Localization messageKey={'type'} fallback={'Type'} />
                </Font>
              </Cell>
              <Cell span={3}>
                <Font type="h4">
                  <Localization
                    messageKey={'text.clientid'}
                    fallback={'Client ID'}
                  />
                </Font>
              </Cell>
              <Cell span={1}>
                <Font type="h4">
                  <Localization
                    messageKey={'text.creationdate'}
                    fallback={'Creation Date'}
                  />
                </Font>
              </Cell>
              <Cell span={2}>
                <Text> </Text>
              </Cell>
            </Row>
            {applicationList.map((item) => (
              <div key={item.clientId} className={rowWrapper}>
                <Row verticalSpacing={3}>
                  <Cell span={3} position={'middle'} className={logoWrapper}>
                    {item.logoPath ? (
                      <img
                        className={applicationLogo}
                        src={item.logoPath}
                        alt={applicationLogoAlt}
                      />
                    ) : (
                      <div className={applicationLogo}>
                        <Avatar
                          backgroundColor="#DAE8F8"
                          textColor="4B8DDC"
                          label={item.name}
                          showLabel={false}
                          showTooltip={false}
                          type="hero"
                        />
                      </div>
                    )}
                    <Font type="h4" className={ellipse}>
                      {item.name}
                    </Font>
                  </Cell>
                  <Cell span={3} position={'middle'}>
                    <Localization
                      messageKey={appTypesMsgKeys[item.type]?.msgKey}
                      fallback={appTypesMsgKeys[item.type]?.fallback}
                    />
                  </Cell>
                  <Cell span={3} position={'middle'} className={ellipse}>
                    <Text>{item.clientId}</Text>
                  </Cell>
                  <Cell span={1} position={'middle'}>
                    <Text>{new Date(item.createdAt).toLocaleDateString()}</Text>
                  </Cell>
                  <Cell span={2} position={'middle'}>
                    <div className={actionWrapper}>
                      <Button
                        className={buttonPadding}
                        text
                        icon={DeleteIcon}
                        onClick={() => openDeleteDialog(item.applicationId)}
                      >
                        <Localization
                          messageKey={'action.delete'}
                          fallback={'Delete'}
                        />
                      </Button>
                      <Link to={`/setup/oauth2-apps/${item.applicationId}`}>
                        <Button className={buttonPadding} text icon={EditIcon}>
                          <Localization
                            messageKey={'action.edit'}
                            fallback={'Edit'}
                          />
                        </Button>
                      </Link>
                    </div>
                  </Cell>
                </Row>
              </div>
            ))}
          </>
        )}
      </Layout>
      {isShowDeleteDialog && (
        <Dialog
          onClose={() => setShowDeleteDialog(false)}
          header={deleteApplicationText}
          footer={() => (
            <>
              <Button primary action={handleApplicationDelete}>
                <Localization
                  messageKey={'action.delete'}
                  fallback={'Delete'}
                />
              </Button>
              <Button text action={closeDeleteDialog}>
                <Localization
                  messageKey={'action.cancel'}
                  fallback={'Cancel'}
                />
              </Button>
            </>
          )}
        >
          <Localization
            messageKey={'application.delete.confirm'}
            fallback={'Are you sure you want to delete this?'}
          />
        </Dialog>
      )}
    </div>
  )
}

const actionWrapper = css`
  display: flex;
  visibility: hidden;
  opacity: 0;
  justify-content: end;
  padding-right: 30px;
  transition: visibility 0s, opacity 0.2s linear;
`

const rowWrapper = css`
  border: 1px solid ${primary.gray(200)};
  margin: 10px 0;
  border-radius: 3px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 2px 4px #00000034;
  }

  &:hover .${actionWrapper} {
    visibility: visible;
    opacity: 1;
  }
`

const applicationLogo = css`
  width: 44px;
  height: 44px;
  margin: 0 18px;
`
const nameCell = css`
  margin-left: 18px;
`
const buttonPadding = css`
  padding-right: 25px;
`

const logoWrapper = css`
  display: flex;
  align-items: center;
`

const ellipse = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
