import { Input, toast, Tooltip } from '@phoenix/all'
import { useLocalization } from '@workfront/localize-react'
import CopyIcon from 'phoenix-icons/dist/CopyIcon.js'
import DeleteIcon from 'phoenix-icons/dist/DeleteIcon.js'
import React from 'react'
import styled, { css } from 'react-emotion'

interface CustomInputProps {
  value: string
  handleDelete?: () => void
  showCopyIcon?: boolean

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any

  children?: never
}

export const CustomInput: React.FC<CustomInputProps> = ({
  showCopyIcon = true,
  handleDelete,
  ...props
}) => {
  const [deleteContent] = useLocalization('tooltip.delete', 'Delete')
  const [copyContent] = useLocalization(
    'tooltip.copytoclipboard',
    'Copy to clipboard'
  )
  const [copySuccessMessage] = useLocalization(
    'toast.copied.successfully',
    'Copied successfully'
  )

  const handleCopy = async () => {
    await navigator.clipboard.writeText(props.value)
    toast.success(copySuccessMessage)
  }

  return (
    <>
      <CustomInputContainerStyled>
        <div>
          <Input
            className={customInputStyles}
            name={'custom-input'}
            {...props}
          />
        </div>
        <div className={customInputIcons}>
          {showCopyIcon && (
            <Tooltip content={copyContent} delay={500} position="right">
              <CopyIcon className={copyDeleteIconStyles} onClick={handleCopy} />
            </Tooltip>
          )}
          {handleDelete && (
            <Tooltip content={deleteContent} delay={500} position="right">
              <DeleteIcon
                className={copyDeleteIconStyles}
                onClick={handleDelete}
              />
            </Tooltip>
          )}
        </div>
      </CustomInputContainerStyled>
    </>
  )
}
CustomInput.displayName = 'CustomInput'

const CustomInputContainerStyled = styled('div')`
  display: flex;
  align-items: center;
`
const customInputStyles = css`
  margin: 8px 0;
  width: 500px;
`

const copyDeleteIconStyles = css`
  margin-left: 8px;
  cursor: pointer;
`

const customInputIcons = css`
  align-self: flex-start;
  margin-top: 10px;
`
